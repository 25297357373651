<template>
  <div class="cropper-area">
    <div class="img-cropper d-flex justify-content-center">
        <vue-cropper
            ref="cropper"
            :src="imgSrc"
            :aspect-ratio="1"
            alt="Source Image"
            :viewMode='2'
            dragMode="move"
            :toggleDragModeOnDblclick="false"
            :cropBoxMovable="true"
            :cropBoxResizable="false"
            :minContainerWidth="200"
            :minContainerHeight="200"
            :minCanvasWidth="100"
            :minCanvasHeight="100"
            :minCropBoxWidth="100"
            :minCropBoxHeight="100"
        ></vue-cropper>
    </div>
    <div class="actions text-center">
        <div class="btn-group btn-group-sm mb-2 me-2" role="group">
          <button type="button" class="btn btn-light"
            @click.prevent="zoom(0.2)"
          > Zoom In </button>
          <button type="button" class="btn btn-light"
            @click.prevent="zoom(-0.2)"
          > Zoom Out </button>
        </div>
        <div class="btn-group btn-group-sm mb-2 me-2" role="group">
            <button type="button" class="btn btn-light"
            @click.prevent="move(-10, 0)"
            > Move Left  </button>
            <button type="button" class="btn btn-light"
            @click.prevent="move(10, 0)"
            >Move Right </button>
        </div>
        <div class="btn-group btn-group-sm  mb-2 me-2" role="group">
            <button type="button" class="btn btn-light"
            @click.prevent="move(0, -10)"
            > Move Up</button>
            <button type="button" class="btn btn-light"
            @click.prevent="move(0, 10)"
            > Move Down </button>
        </div>
        <div class="btn-group btn-group-sm  mb-2 me-2" role="group">
            <button type="button" class="btn btn-light"
            @click.prevent="rotate(90)"
            >Rotate +90 </button>
            <button type="button" class="btn btn-light"
            @click.prevent="rotate(-90)"
            > Rotate -90 </button>
        </div>
        <div class="btn-group btn-group-sm mb-2 me-2" role="group">
            <button ref="flipX" type="button" class="btn btn-light"
            @click.prevent="flipX"
            > Flip X </button>
            <button ref="flipY" type="button" class="btn btn-light"
            @click.prevent="flipY"
            >  Flip Y </button>
        </div>          
         
        <div class="d-flex pt-4 justify-content-end">
            <button type="button" class="me-2 me-md-3 btn btn-dark"
                @click.prevent="reset">Reset</button>
            <button type="button" class="btn btn-primary"
            @click.prevent="cropImage">Accept</button>
        </div>
    </div>
</div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    components: { 
        VueCropper
    },
    props:{
        imgSrc:{
            default: null
        }
    },
    data(){
        return{
            cropImg: '',
            data: null,
        }
    },
    methods:{
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
                const file = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
                this.$emit('completed', file)
            });
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute('data-scale', scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute('data-scale', scale);
        },
        move(offsetX, offsetY) {
            this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
    }
}
</script>

<style>
/* .cropper-area {
  width: 100%;
} */

.actions {
  margin-top: 1rem;
}
/* .crop-placeholder {
  width: 100%;
  height: 100px;
  background: red;
} */
/* .cropped-image img {
  max-width: 100%;
} */
</style>


